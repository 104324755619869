<script>
import APIRequests from '@/mixins/api-requests';

export default {
  name: 'KDASelectedVacancy',

  mixins: [APIRequests],

  data: () => ({
    m_strVacancyNotFound: 'The selected job is no longer available'
  }),

  computed: {
    m_objSelectedVacancy() {
      if ('strID' in this.g_objSelectedVacancy) {
        return this.g_objSelectedVacancy;
      }

      return null;
    }
  },

  mounted() {
    this.getVacancyByID(this.$route.params.strHashedID);
  }
};
</script>
