<template>
  <base-section id="k-d-a-career-form" space="60">
    <v-container class="px-7 px-md-0">
      <v-row
        v-if="m_objSelectedVacancy && m_objSelectedVacancy.strID"
        align="center"
        justify="space-between"
      >
        <v-col cols="12">
          <k-d-a-career-application-form
            :b-submitting="g_bCareerApplicationSubmitting"
            @selectFile="selectFile($event)"
            @changeName="changeName($event)"
            @changeEmail="changeEmail($event)"
            @changeMobilePhone="changeMobilePhone($event)"
            @submitApplication="submitApplication($event)"
          />

          <k-d-a-submitted-modal
            v-model="g_bCareerApplicationSubmitted"
            :str-title="m_objSubmittedMessage.strTitle"
            :str-text="m_objSubmittedMessage.strText"
            text-color="black"
            @input="toggleModal($event)"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import APIRequests from '@/mixins/api-requests';
import KDASelectedVacancy from './extension/KDASelectedVacancy';

export default {
  name: 'KDACareerForm',

  components: {
    KDACareerApplicationForm: () => import('@/components/KDACareerApplicationForm'),
    KDASubmittedModal: () => import('@/components/KDASubmittedModal')
  },

  extends: KDASelectedVacancy,

  mixins: [APIRequests],

  data: () => ({
    m_objSelectedFile: {},
    m_strApplicantName: '',
    m_strApplicantEmail: '',
    m_strApplicantMessage: '',
    m_bFirstTrySubmit: false
  }),

  computed: {
    m_objSubmittedMessage() {
      if (this.g_bCareerApplicationSubmitted && !this.g_bCareerApplicationSuccess) {
        return {
          strTitle: 'Submission Failed!',
          strText: 'Seems like the server is busy. Please try again later.'
        };
      } else {
        return {
          strTitle: 'Thank you for your application',
          strText: 'We will provide further information via email.'
        };
      }
    }
  },

  methods: {
    selectFile(selectedFile) {
      this.m_objSelectedFile = selectedFile;
    },
    changeName(applicantName) {
      this.m_strApplicantName = applicantName;
    },
    changeEmail(applicantEmail) {
      this.m_strApplicantEmail = applicantEmail;
    },
    changeMobilePhone(applicantMessage) {
      this.m_strApplicantMessage = `My Phone Number: ${applicantMessage}`;
    },
    submitApplication(isFormValid) {
      this.m_bFirstTrySubmit = true;
      if (isFormValid && this.m_objSelectedFile instanceof File) {
        this.postApplication(
          this.$route.params.strHashedID,
          this.m_strApplicantName,
          this.m_strApplicantEmail,
          this.m_strApplicantMessage,
          this.m_objSelectedFile
        );
      }
    },
    toggleModal(bShow) {
      this.g_bCareerApplicationSubmitted = false;
      if (this.g_bCareerApplicationSuccess) {
        this.$router.go();
      }
    }
  }
};
</script>
